


























import FilterFabric from '@/views/chat/filters/Filters/FilterFabric'
import CenteredColumnLayout from '@/components/CenteredColumnLayout.vue'
import { FilterConfig, FilterTypes } from '@/views/chat/filters/FilterTypes'
import { InputSetups } from '../../../mixins/input-setups'
import CurrentModule from '@/components/Modules/components/CurrentModule.vue'
import TempCurrentModuleMixin from "@/includes/logic/Modules/mixins/TempCurrentModuleMixin";
import { ModuleTypesEnum } from "@/includes/logic/Modules/types/types";

import { UseFields } from 'piramis-base-components/src/components/Pi/index'
import PageTitle from 'piramis-base-components/src/components/PageTitle.vue'

import { Component, Mixins } from 'vue-property-decorator'

@Component({
  'components': {
    CenteredColumnLayout,
    PageTitle,
    CurrentModule
  },
})
export default class WarningSystem extends Mixins(UseFields, InputSetups, TempCurrentModuleMixin) {
  // filterConfig: FilterConfig | null = null

  created(): void {
    // this.filterConfig = FilterFabric.create(FilterTypes.Warn)

    this.setCurrentModule(ModuleTypesEnum.WarnCommandHandlerModule)
  }
}
